<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A student performed a TLC to track the progress of a reaction. Unfortunately, the spots are
        too large and the different compounds cannot be distinguished. Among the following options,
        select all changes that can be made to correct this error for the student's next TLC
        analysis.
      </p>

      <div v-for="option in options" :key="option.value" class="mb-0 pl-6">
        <v-checkbox
          v-model="inputs.input1"
          class="d-inline-block my-0 py-1"
          :value="option.value"
          :dense="true"
          hide-details="true"
          :ripple="false"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default {
  name: 'uOttawa1321Prelab1Q7',
  components: {
    DragToRankInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {text: 'Changing the solvent in which the compound is dissolved', value: 'changeSolvent'},
        {text: 'Using a smaller capillary', value: 'smallerCapillary'},
        {text: 'Faster application of the compound on the TLC', value: 'applyFaster'},
        {text: 'Drawing the baseline lower on the TLC', value: 'lowerBaseline'},
        {text: 'All of the above', value: 'all'},
      ],
    };
  },
};
</script>
